import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
 
class BillingForm extends Component {

    constructor(props) {
        super(props);  
      };

    state = {
        userEmail:"",  
        billingMonth: "",
        billingYear: "",
        totalCost:"", 
        paymentDueDate:"",
        paymentLink:"",
        paymentStatus:"",
        billingDataAvailable: false
      };

    handleBilling = async (ptoken,userEmail) => {
      console.log(" handleBilling() starts....");
      console.log(" handleBilling() token is....", ptoken);
      console.log(" handleBilling() userEmail....", userEmail);
        
      const data = { 
          "userEmail": userEmail,
          "myStatus": "unpaid"
      };
      const header = {
          headers: {
              'Authorization': `${ptoken}`
            // 'Authorization': `${token}` + 'ftest-bad-token-tail'  //this is for testing cognito authorizer, it works (ie. blocked or get error) if we add any extra chars in the true id_token.
          // 'Authorization': `Basic ${token}` 
          }
        }
  
      console.log("billingForm.handleSubmit()), header is:", header);
      
      
      
      const apiUrl = 'https://c0o8gnzty3.execute-api.us-west-2.amazonaws.com/prod/monthlyBilling'

      axios.post(apiUrl, data,  header)
      .then((response) => {
        
         const billingResp = response.data;
         console.log("billing api call response:", response);
         console.log("billing api call response'Data:", billingResp);
         if (billingResp.length>0) { 
          const monthBillingData = JSON.parse(JSON.stringify(response.data))
          const monthTotalExp= monthBillingData[0].month_total_cost;
          const month = monthBillingData[0].month;
          const year = monthBillingData[0].year;
          const monthSubscriptionFee = monthBillingData[0].month_sub_fee;
          const monthPaymentDueDate = monthBillingData[0].payment_due_date;
          const monthPaymentLink = monthBillingData[0].payment_link;
          const monthPaymentStatus = monthBillingData[0].payment_status

          console.log("month total cost:", monthTotalExp);
          console.log("month, year:", month, year);


          this.setState({billingMonth: month });
          this.setState({billingYear: year });
          this.setState({totalCost: monthTotalExp });
          this.setState({paymentDueDate: monthPaymentDueDate });
          this.setState({paymentLink: monthPaymentLink });
          this.setState({paymentStatus: monthPaymentStatus });
          this.setState({billingDataAvailable: true});

         } else {
          alert ("No new billing statement. Thanks.");

         }   
          
      }).catch((error) => {
          //alert("personToTalkForm.jsx get error at handleTalk");
          alert("Sorry, unexpected error. Please try it again later.");
          console.log("billingForm.jsx, handleSubmit()  get error:", error);
      });
  };

 
  componentDidMount() {
     
  }

  componentDidUpdate() {  
  }

  render() { 
        
        console.log("billingForm starts. ");

        const { billingDataAvailable, billingMonth, billingYear, totalCost,paymentDueDate, paymentLink, paymentStatus } = this.state;
        console.log("in render....month, year, total cost", billingMonth, billingYear, totalCost );
        return (  
          <div>
            {
              billingDataAvailable? (
                <div>
                  <h5 style={{ color: 'white'}}>
                    Please click the "click to pay" under Payment Link to pay your monthly bill online for {billingMonth}/{billingYear} for the total amount: ${totalCost}. 
                    <br></br>
                    After you paid the bill, please click "Get Next Billing Statement" button to see the next billing status. Thank you.
                  </h5>
                  <br></br>
                  <div class="form-row">
                    <div class="col-auto">
                          <label class="my-1 mr-2" for="month">
                          <h6 className = "text-without-bg"> Billing Month </h6> 
                          </label>
                          <p id="month" style={{ color: 'white' }}>{billingMonth}</p>
                    </div>

                    <div class="col-auto">
                          <label class="my-1 mr-2" for="year">
                          <h6 className = "text-without-bg"> Billing Year </h6> 
                          </label>
                          <p id="year" style={{ color: 'white' }}>{billingYear}</p>
                    </div>
                    
                    <div class="col-auto">
                          <label class="my-1 mr-2" for="status">
                          <h6 className = "text-without-bg"> Payment Status </h6> 
                          </label>
                          <p id="status" style={{ color: 'white' }}>{paymentStatus}</p>
                    </div>

                    <div class="col-auto">
                          <label class="my-1 mr-2" for="dueDate">
                          <h6 className = "text-without-bg"> Due Date </h6> 
                          </label>
                          <p id="dueDate" style={{ color: 'white' }}>{paymentDueDate}</p>
                    </div>
                  </div>

                  <br></br>

                  <div class="form-row">
                    <div class="col-auto">
                          <label class="my-1 mr-2" for="total">
                          <h6 className = "text-without-bg"> Monthly Billing Total </h6> 
                          </label>
                          <p id="totalCost" style={{ color: 'white' }}> ${totalCost}</p>
                    </div>

                    <div class="col-auto">
                          <label class="my-1 mr-2" for="paymentLink">
                          <h6 className = "text-without-bg"> Payment Link </h6> 
                          </label>
                          <p>
                            <a 
                              id="paymentLink" 
                              style={{ color: 'white', 
                                  fontWeight: 'bold', 
                                  fontSize: '20px', 
                                  textDecoration: 'underline' }} 
                              href={paymentLink} target="_blank" 
                              rel="noopener noreferrer" > 
                              Click to Pay
                            </a>
                      </p>
                    </div> 
                  </div>
                  <div class="col-md-12 text-left">
                      <button  class="btn btn-primary" onClick = {() => this.handleBilling(this.props.token, this.props.userEmail)}>Get Next Billing Statement</button>
                  </div>
                </div>
                
              ): (
                <div>
                  <h5 style={{ color: 'white'}}>
                    Please click "Get Billing Statement" button to get the billing information. Thank you.
                  </h5>
                  <div class="col-md-12 text-left">
                      <button  class="btn btn-primary" onClick = {() => this.handleBilling(this.props.token, this.props.userEmail)}>Get Billing Statement</button>
                  </div>
                </div>
              )
            }
          </div>
        )             
    } 
} 

export default BillingForm;