//This React func component is updated based on verifier.js at \DPTalk\Dev\login\js
//refer to DevNote: p33: "Create sign-in page with AWS Cognito" section

import jsr from 'jsrsasign'; // run: npm install jsrsasign, first.
import {region, userPoolId, appClientId} from '../App'
import {parseJWTHeader} from '../App';
import {parseJWTPayload} from '../App'

var key_id;
var keys;
//var key_index;  //never be called


//verify token
const verifyToken = async (token) => {
//async function verifyToken (token) {
//get Cognito keys
let keys_url = 'https://cognito-idp.'+ region +'.amazonaws.com/' + userPoolId + '/.well-known/jwks.json';
await fetch(keys_url)
.then((response) => {
    return response.json();
})
.then((data) => {
    keys = data['keys'];
});

//Get the kid (key id)
var tokenHeader = parseJWTHeader(token);
key_id = tokenHeader.kid;

//search for the kid key id in the Cognito Keys
const key = keys.find(key =>key.kid===key_id)
if (key === undefined){
    return "Public key not found in Cognito jwks.json";
}

//verify JWT Signature
var keyObj = jsr.KEYUTIL.getKey(key);
//var isValid = jsr.KJUR.jws.JWS.verifyJWT(token, keyObj, {alg: ["RS256"]});
//****10/19/24: updated the above line by adding gracePeriod: 120 to 
//****fix the login issu that sometimes got error: invalid id token: signature verificaiton failed.
var isValid = jsr.KJUR.jws.JWS.verifyJWT(token, keyObj, {alg: ["RS256"], gracePeriod: 120});
if (isValid){
} else {
    return("***Signature verification failed");
}

//verify token has not expired
var tokenPayload = parseJWTPayload(token);
if (Date.now() >= tokenPayload.exp * 1000) {
    return("Token expired");
}

//verify app_client_id
var n = tokenPayload.aud.localeCompare(appClientId)
if (n != 0){
    return("Token was not issued for this audience");
}

return("verified");
};

export default verifyToken;