import React, { Component } from 'react';

 
class MyLifeMenu extends Component {
    state = {
        isOpen: false
      };
    
      toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });


    render() { 
        const lifeMenuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return ( 

            <div class="btn-group" onClick={this.toggleOpen}>
                <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    My Life
                </button>

                <ul class={lifeMenuClass}> 
                    {/* list of life stages*/}
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("Introduction") }}>Introduction</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("FamilyHistory") }}>Family History</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("EarlyChildhood") }}>Early Childhood</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("SchoolDaysElementary") }}>School Days-Elementary</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("SchoolDaysJuniorHigh") }}>School Days-Junior High</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("SchoolDaysHighSchool") }}>School Days-High School</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("SchoolDaysUniversity") }}>School Days-University</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("FamilyEventsInChildhood") }}>Family Events in Childhood</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("Employment") }}>Employment</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("Marriage") }}>Marriage</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("Children") }}>Children</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("OccupationAndCareer") }}>Occupation and Career</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("Retirement") }}>Retirement</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("ThingsILove") }}>Things I Love</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("LessonsLearnedInLife") }}>Lessons Learned in Life</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("FinalMessage") }}>Final Message</a></li>
                    <li><a class="dropdown-item" onClick={(e)=>{e.preventDefault();this.props.lifeMenuItemOnClick("createLifeVideo") }} style={{ fontWeight: 'bold', color: 'darkblue' }}>Create Live Video</a></li>
                </ul>

            </div>

            
         );
    }
} 

export default MyLifeMenu;