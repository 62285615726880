import React, { Component } from 'react';

/*
class NavBar extends Component {
   // state = {  }

    //copy/pasge bootstrap's navbar in renter/return(), refer to https://getbootstrap.com/docs/5.0/components/navbar/
   /* render() { 
        return ( 
            <nav className="navbar navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        Navbar <span className="badge badge-pill badge-secondary">
                            {this.props.totalCounters}
                        </span>
                    </a>
                </div>
            </nav>
         );
    }*/
/*
    render() { 
        return ( 
            <nav className="navbar navbar-light bg-light">
                <a className="navbar-brand" href="#">
                    Navbar <span className="badge badge-pill badge-secondary">
                        {this.props.totalCounters}
                    </span>
                </a>
                
            </nav>
         );
    }
} */

//stateless functional component. for the components without state, we may just develop a funciton to replace the class component. Both works.
/* const NavBar = (props) => {
    return ( 
        <nav className="navbar navbar-light bg-light">
            <a className="navbar-brand" href="#">
                NavBar {" "}
                <span className="badge badge-pill badge-secondary">
                    { props.totalCounters} 
                </span>
            </a>
            
        </nav>
     );
}*/

const NavBar = (props) => {

  const getLoginFlag = props.getLoginFlag;

  const signInButtonOnClick = props.signInButtonOnClick;

  const signOutMenuOnClick = props.signOutMenuOnClick;

  const buildMyDPMenuOnClick = props.buildMyDPMenuOnClick;

  const talkWithDPMenuOnClick = props.talkWithDPMenuOnClick;
  const instructionMenuOnClick = props.instructionMenuOnClick;
  const contactMenuOnClick = props.contactMenuOnClick; //this is for postLogin ContactUs menu click
  const preLoginContactMenuOnClick = props.preLoginContactMenuOnClick; //this is for prelogin ContactUs menu clicked

  const billingMenuOnClick = props.billingMenuOnClick;
  //02/24/2022
  //add the two button blocks (line#74-76 andline#103-105) below for fixing nav bar not shown on mobile web browser)
  return ( 
    <div>
    { getLoginFlag ? (
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" onClick={instructionMenuOnClick}>Instruction</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" onClick={buildMyDPMenuOnClick}>Build My Legacy</a>
            </li>
            
            <li class="nav-item">
              <a class="nav-link" onClick={talkWithDPMenuOnClick}>Talk With Legacy</a>
            </li>
            {/*<li class="nav-item">
              <a class="nav-link" onClick={billingMenuOnClick}>Billing</a>
            </li> */} 
            <li class="nav-item">
              <a class="nav-link" onClick={contactMenuOnClick} tabindex="-1" aria-disabled="true">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" onClick={signOutMenuOnClick}>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    ): (
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <button class="nav-link" aria-current="page" onClick={signInButtonOnClick}>Login</button>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.missu.ai/index.html">Home</a>
            </li>
            {
           /* <li class="nav-item">
              <a class="nav-link disabled" href="#services">Services</a>
            </li>*/
            }
            <li class="nav-item">
              <a class="nav-link" onClick={preLoginContactMenuOnClick} tabindex="-1" aria-disabled="true">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    ) }

    </div>
  )
      
}
 
export default NavBar;